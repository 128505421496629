import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Link, StaticQuery } from "gatsby";
import { connect } from "react-redux";
import { navigate } from "gatsby";
// import Custom Components
import MainMenu from "./partials/main-menu";
import CartMenu from "./partials/cart-menu";
import CategoryMenu from "./partials/category-menu";
import Logo from "./logo";
import { stickyHeaderHandler, mobileMenu } from "../../utils";
import { getIsAuthenticated, getUser, logout } from "../../reducers/user";
import { showModal } from "../../actions";

const SearchBox = () => {
  const [queryString, setQueryString] = useState("");
  return (
    <div className="header-search-wrapper search-wrapper-wide">
      <label htmlFor={"q"} id="js_1" label="Search" className="sr-only">
        Search
      </label>

      <button
        onClick={(e) => {
          e.preventDefault();
          if (queryString !== "")
            navigate("/allcollection?q=" + encodeURIComponent(queryString));
        }}
        className="btn btn-primary"
        aria-label="Save"
        type="submit"
      >
        <i className="icon-search"></i>
      </button>

      <input
        type="text"
        onChange={(e) => {
          // if(!window.location.pathname.includes("collections")){
          setQueryString(e.target.value);
          // }
        }}
        autoComplete="off"
        className="form-control"
        aria-labelledby="js_1"
        name="q"
        id="q"
        value={queryString}
        placeholder="Find Products ..."
        required
      />
    </div>
  );
};

const MobileSearchBox = () => {
  const [queryString, setQueryString] = useState("");

  return (
    <div className=" d-xs-block d-lg-none">
      {/* <div className="mobile-menu-wrapper"> */}
      <form action="#" method="get" className="mobile-search">
        <label aria-labelledby="mobile-search" className="sr-only">
          <span id="mobile-search"></span> Search
        </label>
        {/* <input type="search" className="form-control" name="mobile-search" id="mobile-search" placeholder="Search product ..." required /> */}
        <input
          type="text"
          onChange={(e) => {
            // if(!window.location.pathname.includes("collections")){
            setQueryString(e.target.value);
            // }
          }}
          autoComplete="off"
          className="form-control"
          aria-labelledby="mobile-search"
          name="search"
          id="search"
          value={queryString}
          placeholder="Search product ..."
          required
        />
        <button
          className="btn btn-primary"
          aria-label="submit button"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            if (queryString !== "")
              navigate("/allcollection?q=" + encodeURIComponent(queryString));
          }}
        >
          <i className="icon-search"></i>
        </button>
      </form>
    </div>
  );
};
function Header(props) {
  const { container = "container", isAuthenticated, logout } = props;
  const [query, setquery] = useState(null);

  useEffect(() => {
    const urlSearch = new URLSearchParams(
      decodeURIComponent(window.location.search)
    );
    const q = urlSearch.get("q");

    setquery(q);
    mobileMenu();

    stickyHeaderHandler();
    window.addEventListener("scroll", stickyHeaderHandler, true);

    return () => {
      window.removeEventListener("scroll", stickyHeaderHandler);
    };
  }, []);

  return (
    <header
      className="header header-intro-clearance header-4"
      style={{ maxWidth: "100vw" }}
    >
      <div className="header-top">
        <div className={container} style={{ maxWidth: "100vw" }}>
          <div className="header-center">
            <StaticQuery
              query={graphql`
                {
                  metadata {
                    message
                  }
                }
              `}
              render={({ metadata }) => {
                const { message } = metadata;
                return message ? (
                  <div className="text-center p-3">
                    <div
                      dangerouslySetInnerHTML={{ __html: message }}
                      className="text-center text-white text-uppercase"
                    ></div>
                  </div>
                ) : null;
              }}
            />
            {/* <Link to="tel:#">
              <i className="icon-phone"></i>Call: +0123 456 789
            </Link> */}
          </div>
        </div>
      </div>

      <div className="header-middle">
        <div className={container} style={{ maxWidth: "100vw" }}>
          <div className="header-left">
            <button className="mobile-menu-toggler">
              <span className="sr-only">Toggle mobile menu</span>
              <i className="icon-bars"></i>
            </button>
            <Logo />
            {/* <Link to={ `/` } className="logo">
                            <img src={ `assets/images/logo.png` } alt="Molla Logo" width={ 105 } height={ 25 } />
                        </Link> */}
          </div>

          <div className="header-center" style={{ flexBasis: "0" }}>
            <div
              className="header-search header-search-extended header-search-visible d-none d-lg-block"
              style={{ marginRight: 0 }}
            >
              <Link to="/" className="search-toggle" role="button">
                <i className="icon-search"></i>
              </Link>
              <form action="#" method="get">
                <SearchBox searchAsYouType={false} q={query} />
              </form>
            </div>
          </div>

          <div className="header-right d-flex flex-end">
            <CartMenu />
            {isAuthenticated ? (
              <>
                {" "}
                <div className="wishlist">
                  <Link to="/dashboard/" data-toggle="modal">
                    {" "}
                    <div className="icon">
                      <i className="icon-user"></i>
                    </div>
                    <p>Account</p>
                  </Link>
                </div>{" "}
                <div className="wishlist">
                  <Link to="/#" onClick={() => logout()} data-toggle="modal">
                    {" "}
                    <div className="icon">
                      <i className="icon-close"></i>
                    </div>
                    <p>Logout</p>
                  </Link>
                </div>{" "}
              </>
            ) : (
              <div className="wishlist">
                <Link to="/login/" data-toggle="modal">
                  <div className="icon">
                    <i className="icon-user"></i>
                  </div>
                  <p>Sign in</p>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      <MobileSearchBox searchAsYouType={false} q={query} />
      <div className="header-bottom sticky-header">
        <div className={container} style={{ maxWidth: "100vw" }}>
          <div className="header-left">
            <CategoryMenu type={3} />
          </div>

          <div className="header-center">
            <MainMenu pages={props.pages} metadata={props.metadata} />
          </div>

          <div className="header-right">
            <i className="la la-lightbulb-o"></i>
            <p>
              Clearance<span className="highlight">&nbsp;Up to 30% Off</span>
            </p>
          </div>
        </div>
      </div>
    </header>
  );
}
const mapStateToProps = (state) => ({
  loggedInUser: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
});

export default connect(mapStateToProps, { showModal, logout })(Header);
