import React from "react";
import { Link } from "gatsby";
import Logo from "./logo";
function Footer(props) {
  const { container = "container", metadata, contact } = props;

  const handleAddress = (e) => {
    const addr =
      "maps.google.com/maps?q=" +
      contact.address +
      "+" +
      contact.city +
      "+" +
      contact.state +
      "+" +
      contact.zip;

    if (navigator.platform.match(/iPhone|iPad|iPod/i))
      window.open(`maps://${addr}`);
    else window.open(`https://${addr}`);

    e.preventDefault();
  };
  return (
    <footer className="footer">
      <div className="footer-middle">
        <div className={container}>
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="widget widget-about my-0">
                <Logo />
                <p style={{ color: "#cccccc" }}>{metadata.description}. </p>

                {/* <div className="widget-call">
                  <i className="icon-phone"></i>
                  Got Question? Call us 24/7
                  <a href="tel:812-562-5622">812-562-5622</a>
                </div> */}
              </div>
            </div>

            <div className="col-sm-6 col-lg-5">
              <div className="widget">
                <p style={{fontSize: "1.6rem"}} className="widget-title">Useful Links</p>

                <ul className="widget-list">
                  {props.pages.nodes.map((page, index) => {
                    return (
                      page.type !== "blog" &&
                      page?.location?.footer && (
                        <li key={index}>
                          <Link
                            style={{ color: "#cccccc" }}
                            to={`/${page.slug}`}
                          >
                            {page.title}
                          </Link>
                        </li>
                      )
                    );
                  })}
                  <li>
                    <Link
                      style={{ color: "#cccccc" }}
                      to={`/${
                        metadata?.pageSettings?.blog?.seoMetaData?.slug ||
                        "blog"
                      }`}
                    >
                      {metadata?.pageSettings?.blog?.seoMetaData?.title ||
                        "Blog"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Customer Service</h4>

                <ul className="widget-list">
                  <li>
                    <Link to="/wholesale">Wholesale</Link>
                  </li>
                  <li>
                    <Link to={`/terms`}>Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <p style={{fontSize: "1.6rem"}} className="widget-title">Contact Us</p>

                <ul className="widget-list">
                  <li style={{ color: "#cccccc" }}>
                    <i className="icon-phone mr-2"></i>{" "}
                    <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                  </li>
                  <li style={{ color: "#cccccc" }}>
                    <i className="icon-home mr-2"></i>
                    <a
                      href="/"
                      onClick={handleAddress}
                    >{`${contact.address}, ${contact.state}, ${contact.zip}`}</a>
                  </li>
                  <li style={{color: "#cccccc"}}>
                    {" "}
                    <i className="icon-mail-reply mr-2"></i>
                    <a href={`mailto:${contact.email}`}>{contact.email}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className={container}>
          <p className="footer-copyright">
            Copyright © {new Date().getFullYear()} {metadata.title}. All Rights
            Reserved. Designed and developed by{" "}
            <a href="https://www.accelerone.com">Accelerone</a>
          </p>
          <figure aria-labelledby="payment-methods" className="footer-payments">
            <img
              loading="lazy"
              src={`/assets/images/payments.png`}
              alt="Payment methods"
              id="payment-methods"
              width="272"
              height="20"
            />
          </figure>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
