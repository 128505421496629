import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import slugify from "slugify";

function CategoryMenu(props) {
  const { type = 1 } = props;

  return (
    <div className="dropdown category-dropdown">
      <Link
        to={`/collections`}
        className="dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-display="static"
        title="Browse Categories"
      >
        Browse Collection
        {type === 3 ? <i className="icon-angle-down"></i> : ""}
      </Link>

      <div className="dropdown-menu">
        <nav aria-label="categories dropdown menu" className="side-nav">
          <ul className="mobile-menu">
            <li className="item-lead" style={{color: 'black'}}>
              <Link to="/collections" tabIndex={-1} style={{color: 'black', padding: '0.3rem 4.5rem 0.3rem 2rem', fontSize: '1.3rem'}}>All Categories</Link>
            </li>
            <li className="item-lead" style={{color: 'black'}}>
              <Link to="/allcollection" tabIndex={-1} style={{color: 'black', padding: '0.3rem 4.5rem 0.3rem 2rem', fontSize: '1.3rem'}}>All Products</Link>
            </li>
          

            <StaticQuery
              query={query}
              render={({ allCategories }) => {
                let categories = allCategories.nodes.filter(
                  (c) => c._id !== "1"
                );
                return categories.map(({ name,itemCount,seoMetaData, subCategories }, i) => {
                
                  const path = `/collections/${seoMetaData?.slug||slugify(name).toLowerCase()}/`;
                  return (
                    <li key={`cat-${i}`} style={{color: 'black'}}>
                      <Link tabIndex={-1} to={path} style={{color: 'black', padding: '0.3rem 4.5rem 0.3rem 2rem', fontSize: '1.3rem'}}>
                        {name}{" "}{itemCount}
                        {/* {subCategories.length > 0 && (
                          <span className="mmenu-btn" style={{color: 'black', display: 'block'}}></span>
                        )} */}
                      </Link>
                      {subCategories.length > 0 && (
                        <ul>
                          {subCategories.map((subcat, j) => {
                            const subpath = `${path}${subcat?.seoMetaData?.slug||slugify(
                              subcat.name
                            ).toLowerCase()}/`;
                            return (
                              <li key={`subcat-${j}`}>
                                <Link tabIndex={-1} to={subpath} style={{color: 'black', padding: '0.3rem 4.5rem 0.3rem 3rem', fontSize: '1.3rem'}}>{subcat.name}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                });
              }}
            />
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default React.memo(CategoryMenu);

const query = graphql`
  {
      allCategories {
      nodes{
        _id
        name
        seoMetaData{
          slug
        }
        subCategories {
          name
          seoMetaData{
            slug
          }
        }
      }
      }
    
  }
`;
