import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

function LogoImage() {
  return (
    <StaticQuery
    query={graphql`{
      logo{
            url {
              childImageSharp {
                gatsbyImageData(height: 70, layout: FIXED)
              }
        }
      }
    }
`}
      render={({ logo }) => (
        <Link to={`/`}  aria-label="logo image">
          <GatsbyImage
            image={logo.url.childImageSharp.gatsbyImageData}
            alt="company logo"
            className="img-fluid" />
        </Link>
      )}
    />
  );
}

export default LogoImage
