import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

export default function MainMenu(props) {
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(window.location.href);
  }, []);

  return (
    <nav aria-label="navigation menu" className="main-nav">
      <ul className="menu sf-arrows">
        <li className={`megamenu-container`} id="menu-home">
          <Link to={`/`}>Home</Link>
        </li>
        {props.metadata?.pageSettings?.blog?.location?.header && (
          <li>
            <Link
              to={`/${
                props.metadata?.pageSettings?.blog?.seoMetaData?.slug || "blog"
              }`}
            >
              {props.metadata?.pageSettings?.blog?.seoMetaData?.title || "Blog"}
            </Link>
          </li>
        )}

        {props.pages.nodes.map((page, index) => {
          return (
            page.type !== "blog" &&
            page?.location?.header && (
              <li
                className={path?.indexOf("pages") > -1 ? "active" : ""}
                key={index}
              >
                <Link to={`/${page.slug}`}>{page.title}</Link>
              </li>
            )
          );
        })}

        <li className={path.indexOf("blog/") > -1 ? "active" : ""}></li>
      </ul>
    </nav>
  );
}
