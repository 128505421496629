import React, { useEffect } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { connect } from "react-redux";
import slugify from "slugify";
import { getCartCount } from "../../../services";
import { removeFromCart, setCart } from "../../../reducers/cart.js";
import { safeContent } from "../../../utils";

function CartMenu(props) {
  const { cartlist, removeProductFromCart, setCart } = props;
  useEffect(() => {
    try {
      const serializedState = localStorage.getItem("state");

      if (serializedState) {
        const persistedState = JSON.parse(serializedState);
        if (persistedState && persistedState.cartlist) {
          setCart(persistedState.cartlist);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [setCart]);

  return (
    <div className="dropdown cart-dropdown">
      <Link
        to={`/cart`}
        className="dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-display="static"
      >
        <div className="icon">
          <i className="icon-shopping-cart"></i>
          <span className="cart-count">{getCartCount(cartlist)}</span>
        </div>

        <p>Cart</p>
      </Link>

      <div
        className={`dropdown-menu dropdown-menu-right ${
          cartlist.length === 0 ? "text-center" : ""
        }`}
      >
        {0 === cartlist.length ? (
          <p>No products in the cart.</p>
        ) : (
          <>
            <div className="dropdown-cart-products">
              {cartlist.map((item, index) => (
                <div className="product" key={`cartlist-${index}`}>
                  <div className="product-cart-details">
                    <h4 className="product-title">
                      <Link
                        to={`/products/${
                          item.seoMetaData?.slug ||
                          slugify(item.title).toLowerCase().replaceAll(".", "")
                        }/`}
                        dangerouslySetInnerHTML={safeContent(item.title)}
                      ></Link>
                    </h4>

                    <span className="cart-product-info">
                      <span className="cart-product-qty">{item.quantity}</span>x
                      $
                      {item.discount
                        ? item.salePrice.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : item.price.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </span>
                  </div>

                  {/* <figure className="product-image-container"> */}
                  <Link
                    to={`/products/${
                      item.seoMetaData?.slug ||
                      slugify(item.title).toLowerCase().replaceAll(".", "")
                    }/`}
                    className="product-image"
                  >
                    <GatsbyImage
                      image={item.thumbnail.childImageSharp?.gatsbyImageData}
                    />

                    {/* <img src={ process.env.PUBLIC_URL + '/' + item.pictures[ 0 ] } data-oi={ process.env.PUBLIC_URL + '/' + item.pictures[ 0 ] } alt="product" /> */}
                  </Link>
                  {/* </figure> */}
                  <button
                    className="btn-remove"
                    title="Remove Product"
                    onClick={() => removeProductFromCart(item._id)}
                  >
                    <i aria-label="sad" className="icon-close"></i>
                  </button>
                </div>
              ))}
            </div>
            <div className="dropdown-cart-total">
              <span>Total</span>

              <span className="cart-total-price">
                {" "}
                $
                {cartlist.length > 0
                  ? Number.parseFloat(
                      cartlist.reduce(
                        (acc, { quantity, price }) => acc + quantity * price,
                        0
                      )
                    ).toFixed(2)
                  : "0"}
              </span>
            </div>

            <div className="dropdown-cart-action">
              <Link to={`/cart`} className="btn btn-primary">
                View Cart
              </Link>
              <Link to={`/checkout`} className="btn btn-outline-primary-2">
                <span>Checkout</span>
                <i className="icon-long-arrow-right"></i>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    cartlist: state.cartlist.items ? state.cartlist.items : [],
  };
}
const mapDispatchToProps = (dispatch) => ({
  setCart: (value) => dispatch(setCart(value)),
  removeProductFromCart: (id) => dispatch(removeFromCart(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartMenu);
