import React, { useEffect } from "react";
import { Provider } from "react-redux";

import store from "../store";
import Header from "../components/common/Header";
import MobileMainNav from "../components/common/mobile-menu";
import Footer from "../components/common/footer";

import QuickView from "../components/features/product/common/quickview";
import ConsentBanner from "../components/common/ConsentBanner";
import { scrollTop } from "../utils/index";
import { ToastContainer } from "react-toastify";
import { useStaticQuery, graphql } from "gatsby";

const MainLayout = ({ children }) => {
  const { allPages, metadata, contact } = useStaticQuery(graphql`
    query pagesQuery {
      contact {
        address
        city
        state
        zip
        phone
        email
        instagram
        facebook
      }
      metadata {
        description
        title

        pageSettings {
          blog {
            title
            seoMetaData {
              title
              
              slug
            }
            location {
              header
              footer
              sidebar
            }
          }
        }
      }
      allPages {
        nodes {
          title
          slug
          location {
            header
            footer
            sidebar
          }
        }
      }
    }
  `);
  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <Provider store={store}>
      <QuickView /> <div className="mobile-menu-overlay"></div>
      <button id="scroll-top" title="Back to Top">
        <i className="icon-arrow-up"></i>
      </button>
      {/* <div className="page-wrapper"> */}
      <Header pages={allPages} metadata={metadata} />
      {children}
      <Footer contact={contact} pages={allPages} metadata={metadata} />
      <ConsentBanner />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
        toastClassName="toast-custom-style"
      />
      <MobileMainNav pages={allPages} metadata={metadata} />
      {/* </div> */}
    </Provider>
  );
};

export default MainLayout;
