import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

import { Link, useStaticQuery, graphql } from "gatsby";
import slugify from "slugify";
import Logo from "../common/logo";
import MobileMainNav from "./partials/mobile-nav";

function MobileMenu({ metadata, pages }) {
  const { allCategories, contact } = useStaticQuery(
    graphql`
      {
        contact {
          instagram
          facebook
        }

        allCategories {
          nodes {
            _id
            name
            seoMetaData {
              slug
              title
              description
            }
            itemCount
            subCategories {
              name
              seoMetaData {
                slug
                title
                description
              }
            }
          }
        }
      }
    `
  );
  let categories = allCategories.nodes.filter((c) => c._id !== "1");
  return (
    <div className="mobile-menu-container">
      <div className="mobile-menu-wrapper">
        <span className="mobile-menu-close">
          <i className="icon-close"></i>
        </span>

        <div style={{ padding: "0px 10px 0px 10px" }}>
          <Logo />
        </div>
        <Tabs defaultIndex={1} selectedTabClassName="show">
          <TabList
            className="nav nav-pills-mobile nav-border-anim"
            role="tablist"
          >
            <Tab className="nav-item">
              <span className="nav-link">Menu</span>
            </Tab>

            <Tab className="nav-item">
              <span className="nav-link">Categories</span>
            </Tab>
          </TabList>

          <div className="tab-content">
            <TabPanel>
              <MobileMainNav metadata={metadata} pages={pages} />
            </TabPanel>

            <TabPanel>
              <nav aria-label="categories dropdown" className="mobile-nav">
                <ul className="mobile-menu">
                  <li className="item-lead">
                    <Link to="/collections" tabIndex={-1} className="mobile-cats-lead">
                     <span id="all-cats">All Categories</span> 
                    </Link>
                  </li>
                  <li className="mobile-cats-lead">
                    <Link tabIndex={-1} aria-labelledby="all-prods" to="/allcollection">
                    <span id="all-prods">All Products</span>  
                      </Link>
                  </li>

                  {categories.map(({ name, itemCount, subCategories }, i) => {
                    const path = `/collections/${slugify(name).toLowerCase()}/`;

                    return (
                      <li key={`item${i}`}>
                        <Link key={`cat${i}`} to={path} tabIndex={-1}>
                          {name} ({itemCount}){" "}
                          <span className="mmenu-btn"></span>
                        </Link>
                        <ul>
                          {subCategories.length > 0 &&
                            subCategories.map((subcat, j) => {
                              const subpath = `${path}${slugify(
                                subcat.name
                              ).toLowerCase()}/`;
                              return (
                                <li key={`subcat${j}`}>
                                  <Link to={subpath} tabIndex={-1}>{subcat.name}</Link>
                                </li>
                              );
                            })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </TabPanel>
          </div>
        </Tabs>

        <div className="social-icons">
          {contact.facebook && (
            <Link
              to="#"
              className="social-icon"
              target="_blank"
              title="Facebook"
            >
              <i className="icon-facebook-f"></i>
            </Link>
          )}

          {contact.instagram && (
            <Link
              to="#"
              className="social-icon"
              target="_blank"
              title="Instagram"
            >
              <i className="icon-instagram"></i>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
export default React.memo(MobileMenu);
