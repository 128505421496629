import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import { mobileMenu } from "../../../utils";

function MobileMainNav({ pages, metadata }) {
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(window.location.href);
  }, []);
  useEffect(() => {
    mobileMenu();
  });

  return (
    <nav aria-label="pages" className="mobile-nav">
      <ul className="mobile-menu">
        {pages.nodes.map((page, index) => {
          return (
            page.type !== "blog" &&
            page?.location?.sidebar && (
              <li
                className={path?.indexOf("pages") > -1 ? "active" : ""}
                key={index}
              >
                <Link to={`/${page.slug}`}>{page.title}</Link>
              </li>
            )
          );
        })}
        <li>
          <Link
            to={`/${metadata?.pageSettings?.blog?.seoMetaData?.slug || "blog"}`}
          >
            {metadata?.pageSettings?.blog?.seoMetaData?.title || "Blog"}
          </Link>
        </li>
        {/* <ul >
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/default/7` }>Default</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/centered/9` }>Centered</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/extended/8` }><span>Extended Info<span className="tip tip-new">New</span></span></Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/gallery/1` }>Gallery</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/sticky/6` }>Sticky Info</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/sidebar/5` }>Boxed With Sidebar</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/fullwidth/2` }>Full Width</Link></li>
                            <li><Link to={ `${process.env.PUBLIC_URL}/product/masonry/4` }>Masonry Sticky Info</Link></li>
                        </ul> */}
      </ul>
    </nav>
  );
}

export default MobileMainNav;
